import { Config } from '@/config';
import apiService from "@/services/api.service";
import { User, UserDto, useUserStore } from '@/store/userStore';

class UserService {
    async updateUser(user: User) {
        const userStore = useUserStore();
        const userId = userStore.getUserId;
        try {

            const response = await apiService.put(`${Config.api.backend.urlV2}/user`, user);
            if ("emailConsent" in user) {
                await apiService.post(`${Config.api.backend.urlV2}/user/:set-consents`, { email_consent: user.emailConsent ? 1 : 0 });
                response.data.emailConsent = user.emailConsent ? 1 : 0
            }
            userStore.setUser(response.data)
            return response;

        } catch (error) {
            return error.response.data;
        }
    }
}

export const userService = new UserService();