import { LanguageTranslation } from "@/store/language.interface";
import { Context } from "@/store/context.interface";
import { User } from '@/store/userStore'

export interface DTOForm {
    id: number;
    type: number;
    section_id: number;
    status: number;
    is_mandatory: boolean;
    user_id: number;
    are_comments_enabled: boolean;
    question: Question;
    answers: Answer;
    localisation_maps: Localization | null;
    approved_date: string | null;
    approved_by: number | null;
    moderation_key: string | null;
    created_at: string;
    updated_at: string;
    statistiques: any | null;
    user: any;
    context: Context[] | [];
    comments: Comment[] | [];
    is_citizen_proposal: number;
    FormResponses: FormResponse[] | null;
    max_answers: number | null;
    iBelieveICareEnabled?: boolean | null;
}

export interface Form {
    id: number;
    type: number;
    sectionId: number;
    status: number;
    isMandatory: boolean;
    userId: number;
    areCommentsEnabled: boolean;
    question: Question;
    answers: Answer;
    localisationMaps: Localization | null;
    approvedDate: string | null;
    approvedBy: number | null;
    moderationKey: string | null;
    createdAt: string;
    updatedAt: string;
    statistiques: any | null;
    user: any; //todo redefine user from store
    context: Context[] | null;
    is_citizen_proposal: number;
    comments: Comment[] | null;
    response: FormResponse[] | null;
    maxAnswers: number | null;
    iBelieveICareEnabled?: boolean | null;

}

interface FormResponse {
    id: number;
    votes: FormVotes
}

interface FormVotes {
    answer_id: number;
    value_id: number;
    value_text: string;
}

interface Image {
    url: string;
    mimetype?: string;
    size?: number;
    name?: string
}

interface Question {
    image: Image;
    title: LanguageTranslation;
    content: LanguageTranslation;
    moderator_comment: LanguageTranslation | null;
}

export interface Answer {
    steps: Step | null;
    rows: Row | null;
    choices: Choice | null;
}


interface Choice {
    id: number;
    text: LanguageTranslation
}

interface Step {
    id: number;
    type: string;
    choice_type: number | null;
    choices: Choice | null;
    name: LanguageTranslation | null;
    range: [number] | null;
}

interface Row {
    id: number;
    text: LanguageTranslation
}

interface Localization {
    longitude: string;
    latitude: string;
}

export interface Comment {
    admin_moderation_reason: string;
    comment: string;
    created_at: string;
    deleted_at: string;
    form_id: number;
    id: number;
    isMandatory: boolean;
    status: number;
    summary: string;
    toxicity: number;
    updated_at: string;
    user: User;
    user_id: number;
    form_vote_id: number;
}


export interface PostFormVoteResponse {
    formId: number,
    consultationId: number,
    sectionId: number,
    vote: any,
    statistiques: any
}

export interface PostFormCommentResponse {
    comment: any,
    sectionId: number,
    consultationId: number,
    formId: number
}

export interface PostFormCommentActionResponse {
    comment: any,
    sectionId: number,
    consultationId: number,
    formId: number,
    commentId: number,
    userId: number,
    action: string,
}

export function parseForm(dtoForm: DTOForm): Form {

    return {
        id: dtoForm.id,
        type: dtoForm.type,
        sectionId: dtoForm.section_id,
        status: dtoForm.status,
        isMandatory: dtoForm.is_mandatory,
        userId: dtoForm.user_id,
        areCommentsEnabled: dtoForm.are_comments_enabled,
        question: dtoForm.question,
        answers: dtoForm.answers,
        localisationMaps: dtoForm.localisation_maps,
        approvedDate: dtoForm.approved_date,
        approvedBy: dtoForm.approved_by,
        moderationKey: dtoForm.moderation_key,
        createdAt: dtoForm.created_at,
        updatedAt: dtoForm.updated_at,
        statistiques: dtoForm.statistiques,
        user: dtoForm.user,
        context: dtoForm.context,
        comments: dtoForm.comments,
        is_citizen_proposal: dtoForm.is_citizen_proposal,
        response: dtoForm.FormResponses,
        maxAnswers: dtoForm.max_answers,
        iBelieveICareEnabled: dtoForm.iBelieveICareEnabled
    };
}
