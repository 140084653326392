// MUST BE SYNC WITH FILE ON API /api/src/constant/index.js

export const constant = {
    FormComments: {
        Status: {
            Deleted: 0,
            Active: 1
        },
        ModerationStatus: {
            Refused: 0,
            Approved: 1,
            Pending: 2
        },
        Action: {
            like: 1,
            flag: 2
        }
    },
    Form: {
        Type: {
            Proposal: 1,
            Question: 2,
            OpenQuestion: 3,
            Level: 4
        },
        Status: {
            Deleted: 0,
            Approved: 1,
            Pending: 2
        }
    },
    Community: {
        Status: {
            Deleted: 0,
            Active: 1,
            PreLaunch: 2
        },
        CustomPage: {
            About: 1,
            Content: 2,
            Redirect: 3,
        }
    },
    Consultation: {
        Status: {
            Over: 0,
            Active: 1,
            PreLaunch: 2
        },
        Type: {
            Public: 1,
            Private: 2
        }
    },
    User: {
        Role: {
            SUPERADMIN: 0,
            ADMIN: 1,
            EDITOR: 2,
            MODERATOR: 3,
            VIEWER: 4
        }
    },
    Context: {
        Parent: {
            consultation: 1,
            section: 2,
            form: 3
        },
        Type: {
            Text: 1,
            Image: 2,
            Video: 3,
            File: 5,
            Link: 6,
        }
    }
};