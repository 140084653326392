<script setup lang="ts">

import { ref, computed, watch, onMounted } from "vue";
import { QSlider } from "quasar";
import { getTranslation } from "@/utils/data-display-utils";
import BaseRateResults from "@/components/base/BaseRateResults.vue";
import { useI18n } from "vue-i18n";
import { useCurrentLocale } from "@/plugins/i18n";

// PROPS
const props = defineProps<{
    levels: any,
    choices: any,
    showResult?: boolean,
    statistiques?: any,
    selectedResponse: any
    results?: any
}>();

const range = props.choices.reduce((acc: any[], item: { value: number; }) => {
    if (item.value < acc[0]) acc[0] = item.value;
    if (item.value > acc[1]) acc[1] = item.value;
    return acc;
}, [Infinity, -Infinity]);

// EMITS
const emit = defineEmits<{
    (e: "change", rateValue: number): void;
}>();

// CONST
const { t } = useI18n();
const rateValue = ref<number>(props.results != null && props.results[props.levels.id - 1].value_id[0] ? props.results[props.levels.id - 1].value_id[0] : range[0]);
const isActive = ref<boolean>(false);
const locale = useCurrentLocale();

// LIFECYCLE 
onMounted(() => {
    if (rateValue.value != range[0]) {
        isActive.value = true
    }
});


// FUNCTIONS
function changeValue(value: any) {
    emit("change", value);
}

function updateColor() {
    isActive.value = true;
}

// WATCH
watch(rateValue, (newValue) => {
        changeValue({ valueId: newValue, answerId: props.levels.answerId })
    }
);

// COMPUTED
const selectedLabel = computed(() => {
    if (isActive.value && rateValue.value != null) {
        let selectedValue = props.choices.find(r => r.value === rateValue.value);
        return selectedValue.label[locale.value];
    } else {
        return t('form.select-value');
    }
})

const endLabels = computed(() => {
    let labels = {}
    let length = props.choices.length

    if (props.levels != undefined) {
        let firstValue = props.choices.find(r => r.value === 1);
        labels[range[0]] = getTranslation(firstValue, 'label');
        let endValue = props.choices.find(r => r.value === length);
        labels[range[1]] = getTranslation(endValue, 'label');
        return labels;
    }
})

const labelColor = computed(() => {
    if (isActive.value) {
        return 'secondary'
    } else {
        return 'white'
    }
})

const labelTextColor = computed(() => {
    if (isActive.value) {
        return 'white'
    } else {
        return 'black'
    }
})

const thumbColor = computed(() => {
    if (isActive.value) {
        return 'secondary'
    } else {
        return 'grey'
    }
})

</script>
<template>
    <div class="question-rate">
        <div v-if="!showResult" class="question">
            <div class="question-rate__title">
                {{ getTranslation(props.levels, 'name') }}
            </div>
            <div class="question-rate__content">
                <q-slider
                    v-model="rateValue"
                    :id="props.levels.id"
                    markers
                    :marker-labels="endLabels"
                    label-always
                    :label-value="selectedLabel"
                    :min="range[0]"
                    :max="range[1]"
                    thumb-size="20px"
                    :thumb-color="thumbColor"
                    :label-color="labelColor"
                    :label-text-color="labelTextColor"
                    inner-track-color="grey"
                    track-color="grey"
                    selection-color="grey"
                    @change="updateColor"
                >
                </q-slider>
            </div>
        </div>
        <div v-else class="result">
            <div class="question-rate__title">
                {{ getTranslation(props.levels, 'name') }}
            </div>
            <div class="question-rate__results">
                <BaseRateResults
                    :statistiques="props.statistiques.step[props.levels.answerId][0]?.percentage || 0"
                    :labels="props.choices"
                    :results="props.results && props.results[props.levels.answerId -1]"/>
            </div>

        </div>

    </div>
</template>
<style lang="scss">
.question-rate {
    background-color: $color-neutral-grey-300;
    padding: $space-sm $space-md;
    border-radius: 8px;
    margin: $space-sm 0;

    &__title {
        font-weight: bold;
        font-size: $small-title;
    }

    &__content {
        padding: $space-lg $space-md $space-md $space-md;
    }

    &__results {
        padding: $space-md;
    }

    .q-slider {
        &__track-container {
            width: auto;
            margin: 0 $space-xl;
        }

        &__marker-labels {
            &-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            &:nth-child(2) {
                right: 0%;
                left: unset !important;
                display: flex;
                justify-self: end;
            }

            &--h-ltr {
                transform: none !important;
            }
        }

        &.active {
            background-color: aqua !important;
        }
    }
}
</style>