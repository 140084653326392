import { Section } from '@/store/section.interface';
import { Context } from '@/store/context.interface';
import { LanguageTranslation } from "@/store/language.interface";

export interface DTOConsultation {
    id: number;
    slug: string;
    begin_date: string;
    end_date: string;
    created_at: string;
    updated_at: string;
    status: number;
    type: string;
    community_id: number;
    order_num: number;
    banner_file: File;
    title: LanguageTranslation;
    description: LanguageTranslation;
    summary: ConsultationSummary;
    welcome_video: LanguageTranslation | null;
    private_email_allowed: PrivateAllowed | null;
}

export interface Consultation {
    id: number;
    slug: string;
    beginDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
    status: number;
    type: string;
    communityId: number;
    orderNum: number;
    bannerFile: File;
    title: LanguageTranslation;
    description: LanguageTranslation;
    section: Section[];
    context: Context[];
    summary: ConsultationSummary;
    welcome_video: LanguageTranslation | null;
    privateEmailAllowed: PrivateAllowed | null;
}

interface PrivateAllowed {
    email: [];
    domain: [];
}

export interface File {
    url: string
}

interface ConsultationSummary {
    sections?: number;
    polls: number;
    surveys: number;
    votes: number;
    user_participation?: number;
}

export function parseConsultation(consultationDto: DTOConsultation): Consultation {
    return {
        id: consultationDto.id,
        slug: consultationDto.slug,
        status: consultationDto.status,
        beginDate: consultationDto.begin_date,
        endDate: consultationDto.end_date,
        type: consultationDto.type,
        communityId: consultationDto.community_id,
        bannerFile: consultationDto.banner_file,
        orderNum: consultationDto.order_num,
        title: consultationDto.title,
        description: consultationDto.description,
        summary: consultationDto.summary,
        section: [],
        context: [],
        createdAt: consultationDto.created_at,
        updatedAt: consultationDto.updated_at,
        welcomeVideo: consultationDto.welcome_video,
        privateEmailAllowed: consultationDto.private_email_allowed
    };
}