<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";
import BaseButton from '@/components/base/BaseButton.vue';
import BaseImageButton from '@/components/base/BaseImageButton.vue';
import BaseInput from "@/components/base/BaseInput.vue";
import { string } from "yup";
import BaseOtherAnswer from "@/components/base/BaseOtherAnswer.vue";
import { ref, watch } from "vue";
import { setLocale } from "@/plugins/i18n";

// PROPS
const props = defineProps<{
    choices: any[];
    otherChoice: boolean;
    maxAnswers?: number;
    selectedResponses: number[];
    otherChoiceLabel: string;
}>();

// EMITS
const emit = defineEmits<{
    (e: "buttonChoiceClick", { response, maxAnswers }): void; // Change event to emit selected responses
    (e: "otherChoiceSet", { response, maxAnswers }): void;
    (e: "otherChoiceReset"): void;
}>();
const answerText = ref<string>("");

// METHODS
function buttonChoiceClick(responseId: number) {
    emit("buttonChoiceClick", { response: responseId, maxAnswers: props.maxAnswers });
}

function otherChoiceReset() {
    emit("otherChoiceReset");
}

function handleUpdateAnswer(value: string) {
    answerText.value = value;
    emit("otherChoiceSet", { response: value, maxAnswers: props.maxAnswers });
}

</script>

<template>
    <div
        class="base-question-answers"
    >
        <div v-for="choice in props.choices">
            <div class="base-question-answers__image" v-if="choice.image">
                <BaseImageButton
                    :choice="choice"
                    @click="buttonChoiceClick(choice.id)"
                    class="primary-outline"
                    :class="{ active: selectedResponses.includes(choice.id) }"
                />
            </div>
            <div class="base-question-answers__text" v-else>
                <BaseButton
                    @click="buttonChoiceClick(choice.id)"
                    :class="{ active: selectedResponses.includes(choice.id) }"
                    class="primary-outline"
                    :text="getTranslation(choice, 'text')"
                    :is-selected="selectedResponses.includes(choice.id)"
                    is-aligned="left"
                ></BaseButton>
            </div>
        </div>
        <BaseOtherAnswer v-if="props.otherChoice"
                         :max-answers="props.maxAnswers"
                         :selected-responses="props.selectedResponses"
                         :answer-text="answerText"
                         @other-choice-reset="otherChoiceReset"
                         @update-answer="handleUpdateAnswer"
                         :other-choice-label="props.otherChoiceLabel"
        />


    </div>

</template>

<style lang="scss">
.base-question-answers {
    &__image {
        width: 100%;

        .q-btn {
            padding: $space-sm 0;
            font-size: $body-text;
        }

        .active {
            background-color: $color-primary-12 !important;
            border: 3px solid $color-primary-50;
            font-weight: 600;
        }
    }

    &__text {
        .base-button {
            span {
                text-align: start !important;
            }
        }

        .active {
            background-color: $color-primary-12 !important;
            border: 3px solid $color-primary-50;
            font-weight: 600;
        }
    }
}
</style>