<script setup lang="ts">
import { ref } from "vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { QDialogProps } from "quasar";

// PROPS
const props = withDefaults(defineProps<{
    isDirty?: boolean;
    position?: QDialogProps["position"];
    haveCloseButton?: boolean,
    persistent?: boolean;
}>(), {
    haveCloseButton: true
});

// EMITS
const emit = defineEmits<{
    (e: "readOnly"): void;
    (e: "close"): void;
}>();

// REFS
const isDialogOpen = ref(false);

// FUNCTIONS
function openDialog() {
    isDialogOpen.value = true;
}

function closeDialog() {
    emit("readOnly");
    emit("close");
    isDialogOpen.value = false;
}

defineExpose({
    openDialog,
    closeDialog,
    isDialogOpen,
});
</script>

<template>
    <q-dialog :seamless="props.isDirty" v-model="isDialogOpen" :position="props.position" :persistent="persistent">
        <q-card class="base-dialog" style="width: 700px; max-width: 80vw;">
            <div class="base-dialog__close-button" v-if="haveCloseButton">
                <BaseButton
                    is-transparent
                    is-round
                    is-icon-only
                    :left-icon="'close'"
                    @click="closeDialog()"
                />
            </div>
            <q-card-section class="base-dialog__title">
                <slot name="title"></slot>
            </q-card-section>

            <q-card-section class="base-dialog__content">
                <slot name="content"/>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<style lang="scss">
.base-dialog {
    display: flex;
    flex-direction: column;

    &.q-card {
        border-radius: 18px;
    }

    &__close-button {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;

        .q-btn-item {
            min-height: 0;
            min-width: 0;
            padding: 4px;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        overflow: auto;
    }
}

</style>
