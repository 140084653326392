<script setup lang="ts">

import BaseButton from './BaseButton.vue';
import BaseQuestionLabel from '@/components/base/BaseQuestionLabel.vue';
import BaseQuestionChoice from "@/components/base/BaseQuestionChoice.vue";
import BaseQuestionOpen from "@/components/base/BaseQuestionOpen.vue";
import BaseQuestionLevel from "@/components/base/BaseQuestionLevel.vue";
import FormButtonContainer from "@/components/FormButtonContainer.vue";
import BaseCommentBox from '@/components/base/BaseCommentBox.vue';
import BaseQuestionRate from "@/components/base/BaseQuestionRate.vue";
import { getTranslation } from "@/utils/data-display-utils";

import { computed, ref } from "vue";
import { Answer } from "@/store/form.Interface";
import { constant } from '@/constant'

// PROPS
const props = defineProps<{
    answers: Answer,
    formType: number,
    maxAnswers?: number,
    response: any,
    statistique: any,
    isBottomBoxOpen: boolean,
    index: number,
    isUserAllowedToVote: boolean,
    iBelieveICareEnabled?: boolean,
    isFinalQuestion: boolean,
    consultationIsCompleted: boolean,
    areCommentsEnabled: boolean,
}>();


// CONST
const isVoting = ref(props.response.length === 0);
const disagreeButtonClicked = ref(false)
const openQuestionAnswered = ref(false)
const voteSelected = ref([]);
const stepActive = ref(1);
const selectedResponses = ref([]);
const otherChoice = ref(null);

// EMITS
const emit = defineEmits<{
    (e: "change", response: object): void;
    (e: "nextForm", index: number): void;
    (e: "submit", voteSelected: any): void;
    (e: "voteNotAllowed"): void;
    (e: "submitComment", userComment): void;
}>();


// FUNCTIONS
function selectResponseChoices(choiceObject: any) {
    const maxAnswers = choiceObject.maxAnswers || 1;
    const response = choiceObject.response;
    if (!props.isUserAllowedToVote) {
        emit("voteNotAllowed");
    } else {
        const index = selectedResponses.value.indexOf(response);
        if (index === -1 && selectedResponses.value.length < maxAnswers) {
            selectedResponses.value.push(response);
        } else if (index !== -1) {
            selectedResponses.value.splice(index, 1);
        }
        const step = props.answers.steps.find(s => s.id === stepActive.value);
        const vsIndex = voteSelected.value.findIndex(v => v.answerId === step.answerId)
        if (vsIndex === -1) {
            voteSelected.value.push({
                answerId: step.answerId,
                valueId: selectedResponses.value
            });
        } else {
            voteSelected.value[vsIndex].valueId = selectedResponses.value
        }
        if (props.formType === constant.Form.Type.Proposal && response > 2) {
            disagreeButtonClicked.value = true;
        } else {
            disagreeButtonClicked.value = false;
            if (selectedResponses.value.length === maxAnswers && props.formType === constant.Form.Type.Proposal && props.iBelieveICareEnabled) {
                stepActive.value = 2;
            }
        }
    }
}

function selectResponseChoiceOther(choiceObject: any) {
    const maxAnswers = choiceObject.maxAnswers || 1;
    const response = choiceObject.response;
    if (!props.isUserAllowedToVote) {
        emit("voteNotAllowed");
    } else {
        otherChoice.value = response;
        const step = props.answers.steps.find(s => s.id === stepActive.value);
        const index = voteSelected.value.findIndex(v => v.answerId === step.answerId)
        if (index === -1) {
            voteSelected.value.push({
                answerId: step.answerId,
                valueId: selectedResponses.value,
                valueText: otherChoice.value
            });
        } else {
            voteSelected.value[index].valueText = otherChoice.value
            if (voteSelected.value[index].valueId.indexOf('o') === -1) {
                voteSelected.value[index].valueId.push('o')

            }
        }
    }
}

function otherChoiceReset() {
    const step = props.answers.steps.find(s => s.id === stepActive.value);
    const index = voteSelected.value.findIndex(v => v.answerId === step.answerId)
    const otherOptionIndex = voteSelected.value[index].valueId.indexOf('o');
    if (otherOptionIndex > -1) {
        voteSelected.value[index].valueId.splice(otherOptionIndex, 1);
        voteSelected.value[index].valueText = ""
    }
}


function selectResponseLevels(response: any) {
    if (!props.isUserAllowedToVote) {
        emit("voteNotAllowed");
    }
    const isAnswered = voteSelected.value.findIndex(vote => vote.anwerId === response.answerId);
    if (isAnswered === -1) {
        voteSelected.value.push({ "answerId": response.answerId, "valueId": [response.valueId] });
    } else {
        voteSelected.value[isAnswered] = { "answerId": response.answerId, "valueId": [response.valueId] }
    }
}

function responseOpen(response: any) {
    if (!props.isUserAllowedToVote) {
        emit("voteNotAllowed");
    } else {
        voteSelected.value[0] = response;
        openQuestionAnswered.value = true
    }
}

function catchScrollAction(index: number) {
    emit("nextForm", index);
}

function resetResponse() {
    stepActive.value = 1;
    selectedResponses.value = [];
    voteSelected.value = [];
    isVoting.value = true
}

function submit() {
    isVoting.value = false
    emit('submit', voteSelected.value)
}

function submitComment(userComment) {
    emit('submitComment', userComment);
}

function cancelVoteChange() {
    isVoting.value = false
}

// COMPUTED
const activeScreen = computed(() => {
    return `step-${stepActive.value}`
})

const stepCount = computed(() => {
    return props.answers && props.answers.steps.length;
})

const showResult = computed(() => {
    return props.response.length > 0;
})

const showSubmit = computed(() => {
    return (voteSelected.value.length > 0 && (activeScreen.value === `step-${stepCount.value}`) || disagreeButtonClicked.value || openQuestionAnswered.value)
})

const hasVotes = computed(() => {
    if (props.response.length > 0) {
        return props.response[0].votes
    } else {
        return null;
    }
})

</script>

<template>
    <div class="base-question">
        <div v-if="props.answers.steps" v-for="(step, index) in props.answers.steps">
            <BaseQuestionChoice
                v-if="!consultationIsCompleted && step.type==='choices' && isVoting && activeScreen === `step-${step.id}`"
                :choices="step.choices.sort((a, b) => a.order_num - b.order_num)"
                :maxAnswers="step.maxAnswers"
                :selected-responses="selectedResponses"
                :other-choice="step.otherChoice"
                @buttonChoiceClick="selectResponseChoices($event)"
                @otherChoiceSet="selectResponseChoiceOther($event)"
                @otherChoiceReset="otherChoiceReset"
                :other-choice-label="step.otherChoice ? getTranslation(step, 'otherChoiceLabel') : ''"
            />
            <BaseQuestionLevel
                v-if="!consultationIsCompleted && step.type==='levels' && isVoting && activeScreen === `step-${step.id}`"
                :levels="step.levels"
                @change="selectResponseLevels($event)"
            />
            <BaseQuestionLabel
                v-if="step.type==='choices' && ((showResult && !isVoting) || consultationIsCompleted)"
                :choices="step.choices"
                :other-choice="step.otherChoice"
                :response="response && response[0]?.votes.filter(r => r.answer_id === step.id)"
                :statistiques="statistique.step[step.id]"
                :other-choice-label="step.otherChoice ? getTranslation(step, 'otherChoiceLabel') : ''"
            />
            <BaseQuestionLevel
                v-if="step.type==='levels' &&  ((showResult && !isVoting) || consultationIsCompleted ) && props.formType != constant.Form.Type.Level"
                :levels="step.levels"
                :statistiques="statistique"
                :show-result="showResult || consultationIsCompleted"
            />
            <base-question-open
                v-if="step.type==='open' && (activeScreen === `step-${step.id}`|| showResult)"
                :open-question="step"
                @change="responseOpen($event)"
                :response="response[0]?.votes.filter(r => r.answer_id === step.id)"
                :show-result="((showResult && !isVoting) || consultationIsCompleted )"
            />
            <div v-if="props.formType === constant.Form.Type.Level" v-for="slider in step.levels">
                <BaseQuestionRate
                    v-if="step.type === 'levels'"
                    :levels="slider"
                    :choices="step.level_choices"
                    :statistiques="statistique"
                    :show-result="(showResult && !isVoting) || consultationIsCompleted"
                    :selected-response="selectedResponses"
                    :results="hasVotes"
                    @change="selectResponseLevels($event)"
                />
            </div>

        </div>

        <div class="base-question__comment"
             v-if="((showResult && !isVoting) && !consultationIsCompleted ) && areCommentsEnabled">
            <div class="base-question__comment-title">{{ $t('form.comment-title') }}</div>
            <base-comment-box @submit-comment="submitComment($event)"/>
        </div>

        <form-button-container
            v-if="isBottomBoxOpen"
            :index="index"
            :is-voting="isVoting"
            :show-result="showResult"
            :show-submit="showSubmit"
            :is-final-question="props.isFinalQuestion"
            @submit="submit"
            @reset-response="resetResponse"
            @next-form="catchScrollAction(index)"
            @cancel-vote-change="cancelVoteChange"
            :consultation-is-completed="consultationIsCompleted"
        />
    </div>
</template>

<style lang="scss">
.base-question {
    display: flex;
    flex-direction: column;

    &__slider {
        display: flex;
        flex-direction: column;

        &-wrapper {
            width: 100%;
        }

        .q-slider {
            width: 50%;
        }
    }

    &-answers {
        width: 100%;

        .q-btn {
            margin-top: 5px;
            margin-bottom: 5px;
            width: 100%;
        }
    }

    &__comment {
        margin: 0 0 $space-sm 0;
        display: flex;
        flex-direction: column;
        gap: $space-xs;

        &-title {
            font-size: $body-text;
        }
    }

    .base-question {

        &__poll-labels {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 5px;

            .q-btn {
                padding: 0;
            }

            .q-linear-progress {
                height: 100%;
            }

            .slider-results {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin: 0px 5px;
                font-size: $small-text;

            }
        }

        &__poll-results {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-container {
                padding: 0px 5px;
                width: 100%;
            }
        }
    }
}
</style>