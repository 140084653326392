import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { CancelTokenSource } from 'axios';
import { useCommunityStore } from '@/store/communityStore';
import { useUserStore } from '@/store/userStore';
import { Answer, Form } from '@/store/form.Interface';
import { Section } from '@/store/section.interface';

const CancelToken = axios.CancelToken;
let cancelTokenForms: CancelTokenSource | null = null;

class FormsService {
    async getForms(section: Section): Promise<Form[]> {
        if (cancelTokenForms) {
            cancelTokenForms.cancel('Multiple requests.');
        }

        cancelTokenForms = CancelToken.source();

        const response = await apiService.get(
            `${Config.api.backend.urlV2}/forms/?section_id=${section.id}&include=statistiques,comments`,
        );

        const formsData: any = response.data;
        if (formsData) {
            const communityStore = useCommunityStore();
            communityStore.setSectionForms(formsData, section);
            communityStore.formsLoaded = true;
        }
        return response.data;
    }

    async getComments(ficheId: number) {
        const response = await apiService.get(`${Config.api.backend.urlV2}/forms/${ficheId}/comments`);

        return response.data;
    }

    async postCommentAction(formId: number, commentId: number, action: string) {
        const userStore = useUserStore();
        const userId = userStore.getUserId;
        const communityStore = useCommunityStore()

        try {
            const response = await apiService.post(`${Config.api.backend.urlV2}/forms/${formId}/comment/${commentId}:set-${action}`);
            communityStore.updateCommentAction(response.data)
            return response;

        } catch (error) {
            console.log(error);
        }
    }

    async postFormVote(answer: Answer, ficheId: number) {

        const userStore = useUserStore();
        const userId = userStore.getUserId;
        const communityStore = useCommunityStore()

        const requestData = {
            userId,
            answers: answer
        };
        try {
            const response = await apiService.post(`${Config.api.backend.urlV2}/forms/${ficheId}:vote`, requestData);
            communityStore.addVoteToFormById(response.data)
            return response;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async postComment(comment: Comment, ficheId: number): Promise<Comment> {

        const userStore = useUserStore();
        const userId = userStore.getUserId;
        const communityStore = useCommunityStore();

        const requestData = {
            userId,
            comment
        };
        try {
            const response = await apiService.post(`${Config.api.backend.urlV2}/forms/${ficheId}:comment`, requestData);
            if (response.data.flagged) {
                return response
            } else {
                communityStore.addCommentToFormById(response.data)
                return response;

            }


        } catch (error) {
            console.log(error)
            return error.response.data;
        }
    }
    async postForm(question, section_id) {
        const requestData = {
            type: 1,
            section_id: section_id,
            question: question,
            answers: {
                steps: [{
                    id: 1,
                    type: 'choices',
                    answerId: 1,
                    choice_template_id: 2
                }]
            }
        };
        try {
            const response = await apiService.post(`${Config.api.backend.urlV2}/forms/`, requestData);
            return response;


        } catch (error) {
            console.log(error)
            return error.response.data;
        }
    }

}

export const formsService = new FormsService();