<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from "vue";

onMounted(() => {
  nextTick(()=> {
    modelValue.value = props.defaultValue ? props.defaultValue : null;
    originalOptions.value = props.options;
  })
});

// EMITS
const emit = defineEmits<{
  (e: "update", newValue: object | null): void;
  (e: "filter", filteredValues: any): void;
}>();

// PROPS
const props = defineProps<{
  options: Object[];
  multiple?: boolean;
  filter?: boolean;
  search?: boolean;
  disabled?: boolean;
  defaultValue?: object
}>();

// REFS
const modelValue = ref<object | null>(null);
const filteredOptions = ref(props.options);
const originalOptions = ref();

// COMPUTED
const localModelValue = computed({
  get() {
    return modelValue.value;
  },
  set(newValue) {
    modelValue.value = newValue;
    emit("update", newValue);
  }
});

// FUNCTIONS
function filterOptions(value: string, update: Function) {
  filteredOptions.value = originalOptions.value;
  update(() => {
    filteredOptions.value = originalOptions.value.filter(
      (option: Object) => option.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    emit("filter", filteredOptions.value);
  });
}
</script>

<template>
  <q-select
    class="base-select"
    v-model="localModelValue"
    :options="props.options"
    option-value="id"
    option-label="name"
    outlined
    :disable="props.disabled"
    input-debounce="0"
    :multiple="props.multiple"
    :use-input="props.search"
    :hide-selected="props.search"
    :fill-input="props.search"
    @filter="filterOptions"

  >
    <template v-slot:option="scope">
      <q-item class="option" v-bind="scope.itemProps">
        <q-item-section>
          <q-item-label class="option__name">{{ $t(scope.opt.name) }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:selected-item="scope">
      {{ $t(scope.opt.name) }}
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> {{ $t("errors.no-results") }}</q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<style lang="scss">

.base-select {
  min-width: 270px;

  .items-center {
    height: 44px;
  }
}

.option {
  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.base-select.q-field {
  .q-field__native {
    line-height: 9px;
    align-items: inherit;
  }

  .q-field__control {
    background-color: $color-neutral-white;
    padding: 0 6px 0 12px;
    min-height: 44px;
    height: 44px;

    &:before {
      border: none;
    }

    &-container {
      align-items: center;
    }
  }

  .q-field__input {
    position: relative;
    bottom: 1px;
  }
}

.q-item.q-item-type {
  padding: 8px;
  height: 44px;
  min-height: 44px;
}
</style>
